/* homepage.css */

/* Glowing heading */
.glowing-heading {
  color: gold;
  animation: glow 1.5s infinite;
}
.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px; /* Adjust as needed */
  height: 100px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}
.whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
}

.whatsapp-button img {
  width: 50px;
  height: 50px;
}

.fallback-icon {
  font-size: 36px; /* Adjust icon size */
  color: #666; /* Adjust color */
}

@keyframes glow {
  0% { text-shadow: 0 0 5px gold, 0 0 10px gold, 0 0 20px yellow; }
  50% { text-shadow: 0 0 10px gold, 0 0 20px gold, 0 0 30px yellow; }
  100% { text-shadow: 0 0 5px gold, 0 0 10px gold, 0 0 20px yellow; }
}

/* Glowing golden border */
.border-gold-glow {
  border: 2px solid gold;
  box-shadow: 0 0 15px gold;
}

/* Vertical scroll animation */
.animate-vertical-scroll {
  animation: scroll-vertical 30s linear infinite;
}

@keyframes scroll-vertical {
  0% { transform: translateY(100%); }
  100% { transform: translateY(-100%); }
}

/* Gold glow button */
.bg-gold-glow {
  background-color: gold;
  transition: background-color 0.3s;
}

.bg-gold-glow:hover {
  background-color: goldenrod;
}

/* Ensure the overall body is responsive */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Make sure the layout adjusts for smaller screens */
@media (max-width: 768px) {
  /* Flex layout adjustments */
  .flex {
    flex-direction: column;
  }

  .md\:w-4\/5 {
    width: 100% !important;  /* Full width for small screens */
  }

  .md\:w-1\/4 {
    width: 100% !important;  /* Full width for small screens */
  }

  .w-full {
    width: 100% !important;  /* Full width on mobile */
  }

  .md\:w-1\/5 {
    width: 100% !important;  /* Full width for small screens */
  }

  /* Adjust text fields, buttons, and select dropdowns */
  .whatsapp-button img {
    width: 50px;  /* Adjust WhatsApp button icon size */
    height: 50px;
  }

  .p-6 {
    padding: 10px; /* Reduce padding on smaller screens */
  }

  /* Featured Tenders Section */
  .featured-tenders-list {
    height: auto; /* Adjust height for small screens */
    overflow-y: auto;
  }

  .featured-tenders-content {
    animation: none !important; /* Disable animation for small screens */
  }

  /* Adjust font size for small screens */
  .text-2xl {
    font-size: 1.5rem;  /* Adjust heading font size */
  }

  .text-3xl {
    font-size: 2rem;  /* Adjust heading font size */
  }

  /* Button and form controls */
  .py-3, .px-6 {
    padding: 10px 15px !important;  /* Adjust button padding */
  }
}

/* For very small screens like phones in portrait mode */
@media (max-width: 480px) {
  .text-xl {
    font-size: 1.2rem; /* Adjust text size for small screens */
  }

  .w-32 {
    width: 50px;  /* Make images smaller */
    height: 50px;
  }

  .w-full {
    width: 100% !important;
  }

  .space-x-2 {
    margin-left: 0;
    margin-right: 0;
  }
}
