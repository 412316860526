/* Global styles for the page */
.pricing-container {
    background-color: #f9fafb;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  
  .card {
    transition: transform 0.3s ease-in-out;
  }
  
  .card:hover {
    transform: scale(1.05);
  }
  
  .card-focused {
    border: 3px solid #ffcc00;
    transform: scale(1.1);
  }
  
  ul {
    padding-left: 1.25rem;
  }
  
  h3 {
    font-size: 1.875rem;
    font-weight: 700;
  }
  
  p {
    font-size: 1.125rem;
  }
  
  /* Adding some specific card styling */
  .bg-gradient-to-r {
    background-image: linear-gradient(to right, #00f260, #0575e6);
  }
  
  .bg-gradient-to-r.from-green-400 {
    background: linear-gradient(to right, #00f260, #0575e6);
  }
  
  .bg-gradient-to-r.from-purple-400 {
    background: linear-gradient(to right, #7b2ff7, #ff6a00);
  }
  
  .bg-gradient-to-r.from-yellow-400 {
    background: linear-gradient(to right, #fbbf24, #f472b6);
  }
  
  .text-white {
    color: white;
  }
  
  .shadow-xl {
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.15);
  }
  /* Global styles for the page */
.pricing-container {
  background-color: #f9fafb;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.navbar {
  background-color: #2d3748;
}

.navbar a {
  color: white;
  text-decoration: none;
  font-weight: 600;
}

.navbar a:hover {
  color: #fbbf24;
}

.card {
  transition: transform 0.3s ease-in-out;
}

.card:hover {
  transform: scale(1.05);
}

.card-focused {
  border: 3px solid #ffcc00;
  transform: scale(1.1);
}

ul {
  padding-left: 1.25rem;
}

h3 {
  font-size: 2.25rem;
  font-weight: 700;
}

p {
  font-size: 1.25rem;
}

/* Adding some specific card styling */
.bg-gradient-to-r {
  background-image: linear-gradient(to right, #00f260, #0575e6);
}

.bg-gradient-to-r.from-green-400 {
  background: linear-gradient(to right, #00f260, #0575e6);
}

.bg-gradient-to-r.from-purple-400 {
  background: linear-gradient(to right, #7b2ff7, #ff6a00);
}

.bg-gradient-to-r.from-yellow-400 {
  background: linear-gradient(to right, #fbbf24, #f472b6);
}

.text-white {
  color: white;
}

.shadow-xl {
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.15);
}

/* Footer styles */
footer {
  background-color: #2d3748;
  padding: 2rem;
  color: white;
}

footer p {
  font-size: 1rem;
  font-weight: 500;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .pricing-container {
    padding: 4rem 2rem;
  }

  .navbar {
    padding: 1rem;
  }

  .pricing-container .flex {
    flex-direction: column;
    align-items: center;
  }

  .pricing-container .flex-wrap {
    flex-wrap: wrap;
    justify-content: center;
  }

  .navbar h1 {
    font-size: 1.5rem;
  }

  .card {
    max-width: 100%;
    min-height: 350px; /* Increase the minimum height of the cards */
  }
}
