.modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    padding: 10px;
  }
  
  .modal-content {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    max-width: 900px;
    width: 100%;
    max-height: 90vh;
    overflow-y: auto;
  }
  
  .modal-title {
    font-weight: 600;
    color: #3f51b5;
  }
  
  .documents-list {
    max-height: 400px;
    overflow-y: auto;
    margin-top: 15px;
  }
  
  .document-item {
    margin-bottom: 15px;
  }
  
  .modal-actions {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
  
  /* button {
    background-color: #3f51b5;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  } */
  
  button:hover {
    background-color: #2c387e;
  }
  