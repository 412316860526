.logo-carousel-container {
    width: 100%;
  }
  
  button {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  button:focus {
    outline: none;
  }
  
  .rounded-full {
    border-radius: 50%;
  }
  
  .w-24,
  .h-24 {
    width: 6rem; /* Adjust as needed */
    height: 6rem;
  }
  
  .w-20,
  .h-20 {
    width: 5rem; /* Adjust as needed */
    height: 5rem;
  }
  
  .flex {
    display: flex;
  }
  
  .space-x-6 > * + * {
    margin-left: 1.5rem; /* Adds space between logos */
  }
  
  .shadow-md {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  