/* src/components/tender/BidModal.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    width: 90%;
    max-width: 700px;
    position: relative;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .modal-title {
    font-size: 1.75rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .input-section {
    margin-bottom: 20px;
    padding: 15px;
    border-bottom: 1px solid #ddd;
  }
  
  .label {
    display: block;
    font-size: 1rem;
    margin-bottom: 8px;
    color: #555;
  }
  
  .input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .file-upload {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding: 5px;
    border-bottom: 1px solid #ddd;
  }
  
  .doc-name {
    flex: 1;
    font-size: 0.95rem;
    color: #555;
  }
  
  .upload-button {
    background-color: #007bff;
    color: white;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;
    border: none;
  }
  
  .file-input {
    display: none;
  }
  
  .preview-image {
    width: 40px;
    height: 40px;
    margin-left: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
  }
  
  .submit-button {
    width: 100%;
    padding: 12px;
    background-color: #28a745;
    color: white;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #28a745;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  